<template>
    <Teleport to="body">
        <Transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
        >
            <div
                class="fm-kefu-dialog fixed left-0 top-0 z-[1000] flex h-screen w-screen flex-col items-center justify-center gap-10 bg-black bg-opacity-40"
                v-show="innerVisible"
                @click="close"
                v-bind="$attrs"
            >
                <Kefu v-bind="$attrs" />
                <Icon icon="icon-close2-line text-white" size="2" class="text-whte cursor-pointer hover:scale-125" />
            </div>
        </Transition>
    </Teleport>
</template>

<script setup lang="ts">
import { useVModel } from "@vueuse/core";
const props = defineProps<{
    /** 是否可见 */
    visible: boolean;
}>();
const emit = defineEmits(["update:visible"]);
const innerVisible = useVModel(props, "visible", emit);

function close() {
    innerVisible.value = false;
}
</script>

<style lang="scss" scoped>
.fm-kefu-dialog.animate__animated {
    animation-duration: 0.2s;
}
</style>
