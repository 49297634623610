<template>
    <div class="fm-kefu flex flex-col items-center gap-5 rounded-lg border-4 border-solid border-primary bg-white p-10">
        <img v-if="src" :src="src" alt="客服" class="w-56" />
        <img v-else src="/assets/img/kefu3.jpg" alt="客服" class="w-56" />
        <span class="text-center text-lg text-primary" v-html="props.describtion"></span>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    describtion: {
        type: String,
        default: `微信扫码添加微信<br/>获取详情`,
    },
    src: String,
});
</script>
